body {
  background-color: var(--global-body-bg-color);
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.primary-color {
  color: var(--global-primary-color);
}

.main-box {
  background-color: #fff !important;
  border: 1px solid #EAEAEA;
  border-radius: 10px !important;
}

.primary-btn,
.primary-btn:hover {
  background-color: var(--global-primary-color);
  color: #fff;
}

.sahl-logo {
  width: 70px;
  height: 35px;
}

.product-card {
  border: 1px #EAEAEA solid;
  border-radius: 10px;
  margin-bottom: 20px;
}

.price-color {
  color: #7C8DB5
}

@media screen and (min-width: 1007px) {
  .product-card {
    margin-left: 6%;
  }
}